<template>
   <div class="container-register-sleep">
      <div class="box-register-sleep">
         <div class="img-sleep-register">
            <img src="../assets/img/sleepNewLogo.png" alt="" />
         </div>

         <div class="title-sleep-register">
            <p>Welcome to</p>
            <p>Sleep-well.coach</p>
         </div>

         <div class="paragraf-register">Sign up with</div>
         <div class="button-social">
            <SWButton :href="googleUrl" class="btn-reg" variant="secondary" size="big">Google</SWButton>
            <SWButton :href="twitterUrl" class="btn-reg" variant="secondary" size="big"> Twitter </SWButton>
            <SWButton :href="facebookUrl" class="btn-reg" variant="secondary" size="big"> Facebook </SWButton>
         </div>
         <div class="paragraf-current-one">or</div>
         <div class="reg-box">
            <button @click="toggleIsEmailForm" class="paragraf-current" :class="isEmailForm ? 'active' : ''">
               Email
            </button>
            <button @click="toggleIsEmailForm" class="paragraf-current" :class="!isEmailForm ? 'active' : ''">
               Phone
            </button>
         </div>
         <!-- <router-view></router-view> -->
         <form @submit.prevent="submitHandler">
            <div class="register-input">
               <div class="name-registar">
                  <TheInput
                     class="corent-margin"
                     v-model="firstName"
                     name="first_name"
                     @input="$v.firstName.$touch()"
                     :errorMessage="firstNameError"
                     placeholder="First Name"
                     :isError="$v.firstName.$error && !!firstNameError"
                  ></TheInput>
                  <TheInput
                     v-model="lastName"
                     @input="$v.lastName.$touch()"
                     :isError="$v.lastName.$error && !!lastNameError"
                     :errorMessage="lastNameError"
                     name="last_name"
                     placeholder="Last Name"
                  ></TheInput>
               </div>
               <TheInput
                  v-if="isEmailForm"
                  class="margin-inp"
                  name="email"
                  v-model="email"
                  @input="$v.email.$touch()"
                  placeholder="Email"
                  :isError="$v.email.$error && !!emailError"
                  type="email"
                  :errorMessage="emailError"
               ></TheInput>

               <TheInput
                  v-if="!isEmailForm"
                  class="margin-inp"
                  name="phone"
                  v-model="phone"
                  @input="$v.phone.$touch()"
                  placeholder="Phone"
                  :isError="$v.phone.$error && !!phoneError"
                  type="phone"
                  :errorMessage="phoneError"
                  v-phone-number
               ></TheInput>
               <TheInput
                  class="margin-inp"
                  placeholder="Password"
                  v-model="password"
                  autocomplete="new-password"
                  type="password"
                  @input="$v.password.$touch()"
                  :isError="$v.password.$error && !!passwordError"
                  :hasShowIcon="true"
                  :errorMessage="passwordError"
               ></TheInput>
              
               <SWButton :disabled="disabled" type="submit" variant="primari" size="big"
                  >Sign up with {{ isEmailForm ? 'email ' : 'phone' }}</SWButton
               >
            </div>
            <div class="footer-sleep-register">
               <div class="paragraf">
                  <p>Signing in for a Sleep-well.coach account means you agree to the</p>
               </div>
               <FooterRegistartion />
            </div>
         </form>
      </div>
   </div>
</template>

<script>
import { SWButton } from 'sw-ui-kit';
import TheInput from '../components/UI/TheInput.vue';
import FooterRegistartion from '../components/FooterRegistartion.vue';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { hasNumberPhone, hasPassword, hasEmail } from '../validation/validation';

export default {
   components: {
      FooterRegistartion,
      TheInput,
      SWButton,
   },
   mixins: [validationMixin],

   data() {
      return {
         firstName: '',
         lastName: '',
         password: null,
         email: '',
         phone: null,
         isEmailForm: true,
         googleUrl: null,
         twitterUrl: null,
         facebookUrl: null,
      };
   },
   validations: {
      firstName: {
         required,
         maxLength: maxLength(32),
         minLength: minLength(1),
      },
      lastName: {
         required,
         maxLength: maxLength(32),
         minLength: minLength(1),
      },
      password: {
         required,
         hasPassword,
         maxLength: maxLength(20),
         minLength: minLength(8),
      },
      email: { required, hasEmail },
      phone: { required, maxLength: maxLength(21), minLength: minLength(10), hasNumberPhone },
   },

   //TODO зробити масив помилок
   computed: {
      emailError() {
         if (!this.$v.email.required) return 'The field must be filled';
         if (!this.$v.email.hasEmail)
            return 'The email can have Latin capital and small letters,numbers and a special symbol (! # $ % & ‘ * + — / = ? ^ _ ` { | } ~;)';
         return '';
      },
      passwordError() {
         if (!this.$v.password.required) return 'The field must be filled';
         if (!this.$v.password.minLength) return 'Password must be at least 8 characters long';
         if (!this.$v.password.maxLength) return 'Password must not exceed 20 characters';
         if (!this.$v.password.hasPassword)
            return 'The password must have Latin capital and small letters,numbers and a special symbol ( ! @ # $ & + - ?)';
         return '';
      },
      firstNameError() {
         if (!this.$v.firstName.required) return 'The field must be filled';
         if (!this.$v.firstName.minLength) return 'The firstName must consist of a minimum of characters';
         if (!this.$v.firstName.maxLength) return 'The firstName should not exceed 32 characters';
         if (!this.$v.firstName.hasUpperlowerCase)
            return 'The name can have Latin capital and small letters, numbers and a special symbol (@ # $ ! & ? ^ _)';
         return '';
      },
      lastNameError() {
         if (!this.$v.lastName.required) return 'The field must be filled';
         if (!this.$v.lastName.minLength) return 'The lastName must consist of a minimum of characters';
         if (!this.$v.lastName.maxLength) return 'The lastName must consist of a minimum of characters';
         if (!this.$v.lastName.hasUpperlowerCase)
            return 'The name must have Latin capital and small letters and a special symbol (@ # $ ! & ? ^ _)';
         return '';
      },
      phoneError() {
         if (!this.$v.phone.required) return 'The field must be filled';
         if (!this.$v.phone.maxLength) return 'Phone must not exceed 20 numbers';
         if (!this.$v.phone.minLength) return 'Phone must be exceed 10 numbers';
         if (!this.$v.phone.hasNumberPhone) return 'Phone can have numbers special symbol "+ () -" and length max22 ';
         return '';
      },
      disabled() {
         return (
            this.$v.lastName.$invalid ||
            this.$v.password.$invalid ||
            this.$v.firstName.$invalid ||
            (this.isEmailForm ? this.$v.email.$invalid : this.$v.phone.$invalid)
         );
      },
   },
   created() {
      if (!sessionStorage.IsFromMane) {
         this.$router.push({ name: 'home' });
      }
      this.fetchUrlGoogle();
      this.fetchUrlFacebook();
      this.fetchUrlTwitter();
   },
   methods: {
      toggleIsEmailForm() {
         this.isEmailForm = !this.isEmailForm;
      },
      submitHandler() {
         let provider = 'email';
         const result = {
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
         };
         if (this.isEmailForm) {
            provider = 'email';
            result.email = this.email;
         } else {
            provider = 'phone';
            this.phone = this.phone.charAt(0) === '+' ? this.phone : '+' + this.phone;

            result.phone = this.phone;
         }

         console.log(result, provider);
         //  if (!this.isEmailForm) this.$v.phone.$touch();
         this.register(result, provider);
      },

      async register(data, provider) {
         try {
            const response = await this.$http.post(`/auth/register/${provider}`, data, { withCredentials: true });

            if (response && provider === 'email') {
               this.$router.push({ name: 'confirmemail' });
            } else if (response && provider === 'phone') {
               this.$router.push({ name: 'registerphone', params: { phone: data.phone } });
            }
         } catch (e) {
            console.log(e);
         }
      },

      async authGoogle() {
         let url = await this.fetchUrlGoogle();
         window.open(url);
      },

      async fetchUrlGoogle() {
         let response = await this.$http.post('auth/google/url');
         this.googleUrl = response.data.url;
         return response.data.url;
      },

      async authFacebook() {
         let url = await this.fetchUrlFacebook();
         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrlFacebook() {
         let response = await this.$http.post('auth/facebook/url');
         this.facebookUrl = response.data.url;
         return response.data.url;
      },

      async authTwitter() {
         let url = await this.fetchUrlTwitter();

         if (this.isAuth) localStorage.isLogin = true;
         window.open(url);
      },

      async fetchUrlTwitter() {
         let response = await this.$http.post('auth/twitter/url', null, { withCredentials: true });
         this.twitterUrl = response.data.redirectUrl;
         return response.data.redirectUrl;
      },
   },
};
</script>

<style lang="sass" scoped>
.name-registar
	display: flex
	min-width: 175px
	width: fit-content
	margin-bottom: 15px
.corent-margin
	margin-right: 8px
.reg-box
	display: flex
	justify-content: center
.reg-box:nth-child(1)
	margin-right: 20px

.reg-box
	@media screen and (max-width:380px)
		margin-top: -10px
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.5px
	text-align: center
.paragraf-register
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	margin-top: 16px
	margin-bottom: 16px
	@media screen and (max-width:380px)
		margin-bottom: 5px
.paragraf-current
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #828282
	cursor: pointer
	padding: 15px
.active
	color: #11151F
	text-decoration: underline
.paragraf-current-one
	font-weight: 500
	line-height: 18.2px
	text-align: center
	padding-top: 15px
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px

.button-social
	display: flex
	justify-content: space-between
.btn-reg
	width: 100%
	font-size: 14px
	font-weight: 400
.btn-reg:nth-child(2)
	margin-right: 10px
	margin-left: 10px
.register-input
	margin-bottom: 32px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.margin-inp
	margin-bottom: 16px
</style>
